



































import Vue from 'vue'
import Component from 'vue-class-component'
import AppHeader from "@/modules/genprox/components/AppHeader.vue";
import AppSideBar from "@/modules/genprox/components/AppSideBar.vue";
import {BSpinner} from "bootstrap-vue";
import FadeInOut from "@/components/animations/FadeInOut.vue";
import {Watch} from "vue-property-decorator";
import {Route} from "vue-router";
import {mapState} from "vuex";
import {UserContext} from "@/modules/genprox/models/User";
import { AccessModules } from '../store/types';
import { LiveChatWidget } from '@livechat/widget-vue/v2';
import SygniCookiesBar from '@/components/SygniCookiesBar.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import { EventBus } from '@/main';
import { REDIRECTED_PATH } from '../genprox.routing';

@Component({
  components: { FadeInOut , AppSideBar, AppHeader, BSpinner, LiveChatWidget, SygniLoader, SygniCookiesBar},
  computed: {
    ...mapState('genprox', {
      activeUserContext: (state: any) => state.activeUserData?.context,
      activeUserData: (state: any) => state.activeUserData,
      accessModules: (state: any) => state.accessModules,
    }),
    enterTime() {
      return this.$route.path.includes('access-denied') ? 0 : 1800;
    },
    leaveTime() {
      return this.$route.path.includes('access-denied') ? 0 : 1;
    }
  }
})
export default class GenproxModule extends Vue {

  @Watch('$route', {immediate: true, deep: true}) onRouteChange( newRoute: Route, oldRoute: Route ){
    newRoute; oldRoute;
    this.scrollContentToTop();
  }

  isLoading: boolean = false;
  isReady?: boolean = false;
  activeUserContext!: UserContext;
  activeUserData!: any;
  accessModules!: AccessModules;
  enterTime!: Number;
  leaveTime!: Number;

  async beforeMount(){
    await this.getAppSetupData();
    await this.performMenuGuardCheck();

    this.isReady = true;
  }

  get userName() {
    if(this.activeUserData) {
      return `${this.activeUserData.user?.firstName} ${this.activeUserData.user?.surname}`;
    }

    return '';
  }

  get userEmail() {
    if(this.activeUserData) {
      return this.activeUserData.user.email;
    }

    return '';
  }

  clearViewedData() {
    this.$store.commit('auth/setViewedCompany', '')
    this.$store.commit('auth/setViewedFund', '')
    this.$store.commit('auth/setInvestedCompanies', [])
    this.$store.commit('auth/setInvestedFunds', [])
  }

  async setUserContextHandler(activeItem: any, redirect: string = null) {
    this.isLoading = true;

    if (activeItem?.context === 'advisor-panel') {
      await this.redirectToBrokerPanel(activeItem)
      this.isLoading = false
      return
    }

    await this.$store.dispatch('genprox/setUserContext', activeItem);
    this.$store.commit('auth/setRequireTwoFactor', false);
    this.clearViewedData()
    
    const promiseUserContexts = this.$store.dispatch('genprox/getUserContexts');
    const promiseAccessModules = this.$store.dispatch('genprox/getAccessModules');
    const promiseGetMenus = this.$store.dispatch('genprox/getMenus');
    const promiseGetCMSWelcome = this.$store.dispatch('genprox/getCMSWelcome');
    const promiseGetActiveUserData = this.$store.dispatch('genprox/getActiveUserData');
    
    await Promise.all([promiseUserContexts,  promiseAccessModules, promiseGetMenus, promiseGetCMSWelcome, promiseGetActiveUserData]);
    this.$store.commit('accounting/clearDictionaries');
    
    try {
      await this.$store.dispatch('genprox/getUserPackages');
    } catch (err) {
      console.log(err);
    }

    localStorage?.removeItem(REDIRECTED_PATH)

    if (this.requireTwoFactor && !this.activeUserData.user.twoFactorAuthEnabled) {
      this.$router.push({name: 'profile-edit-security'})
    } else {
      try {
        if (redirect) {
          this.$router.push(redirect)
        } else {
          switch (activeItem?.context?.toLowerCase()) {
            case 'fund':
              if (this.$route?.name != 'welcome') {
                await this.$router.push({ name: 'welcome' });
              }
              await this.$router.push('/fund/dashboard')
              break;
            case 'company':
              if (this.$route?.name != 'welcome') {
                await this.$router.push({ name: 'welcome' });
              }
              await this.$router.push('/company/dashboard')
              break;
            case 'fund-manager':
              if (this.$route?.name != 'welcome') {
                await this.$router.push({ name: 'welcome' });
              }
              await this.$router.push('/fund-manager/dashboard')
              break;
            default:
              await this.$router.push({ name: 'welcome' })
              break;
          }
        }
      } catch (e) {
        e;
      }
    }
    

    sessionStorage?.removeItem('reportData');
    this.$store.commit('regReporting/resetNewReportFormData');

    EventBus.$emit('contextChanged', activeItem);

    this.isLoading = false;
  }

  get requireTwoFactor() {
    return this.$store.getters['auth/requireTwoFactor'];
  }

  get actionItems() {
    const leftActionItems = (this.$refs?.formBarLeftActionItems as any)?.passengers || []
    const rightActionItems = (this.$refs?.formBarRightActionItems as any)?.passengers || []

    return { leftActionItems, rightActionItems }
  }

  get showActionBar() {
    return (this.actionItems?.leftActionItems || [])?.length > 0 || (this.actionItems?.rightActionItems || [])?.length > 0
  }

  async redirectToBrokerPanel(context: any) {
    try {
      const redirectToken = await this.$store.dispatch('auth/generateChangedLoggedContext', context?.id)
      window.open(`${context?.www}/login-with-redirect-token?token=${redirectToken}`)
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async getAppSetupData(): Promise<void> {
    if(!localStorage.getItem('apiKey') || !localStorage.getItem('refreshToken')) {
      this.$router.push('/auth/login');
    }

    
    await this.$store.dispatch('genprox/getUserContexts');

    const currentContext: any = this.$store.getters['auth/jwtTokenData']
    
    if (currentContext?.context_type === 'advisor-panel') {
      const contexts: any[] = this.$store.getters['genprox/userContexts']
      const correctContext: any = contexts.find((el: any) => el.context !== 'advisor-panel')
      if (correctContext) {
        await this.$store.dispatch('genprox/setUserContext', { id: correctContext.id })
      }
    }

    await Promise.all([this.$store.dispatch('genprox/getAccessModules'), this.$store.dispatch('genprox/getMenus'), this.$store.dispatch('genprox/getCMSWelcome'), this.$store.dispatch('genprox/getActiveUserData')]);

    try {
      await this.$store.dispatch('genprox/getUserPackages');
    } catch (err) {
      console.log(err);
    }
  }

  private async performMenuGuardCheck(): Promise<void> {
    if(this.$route.query.redirect){
      try{
        await this.$router.push({path: (this.$route.query.redirect as string)});
      } catch(e) {
        const query = Object.assign({}, this.$route.query);
        delete query.redirect;
        await this.$router.replace({query});
      }
    }
  }

  get isFullWidth(): boolean {
    const fullScreenRoutes = ['fund-reg-xml-preview', 'fund-xml-preview'];
    return fullScreenRoutes.includes(this.$route.name);
  }

  get isWelcomePage(): boolean {
    return this.$route.name === 'welcome';
  }

  scrollContentToTop(): void {
    const appContentDiv: HTMLDivElement = this.$refs['appContent'] as HTMLDivElement | undefined;
    appContentDiv?.scrollTo({top: 0});
  }

  created() {
    this.$root.$on('setUserContext', (activeItem: any, redirect: string) => {
      this.setUserContextHandler(activeItem, redirect)
    })
  }
}
