var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showCookies ? _c('div', {
    staticClass: "sygni-cookies-bar"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "sygni-cookies-bar__container"
  }, [_c('div', {
    staticClass: "sygni-cookies-bar__text",
    domProps: {
      "innerHTML": _vm._s(_vm.cookiesMessage)
    }
  }), _c('sygni-rounded-button', {
    staticClass: "sygni-cookies-bar__btn filled gn-primary",
    attrs: {
      "id": "fe_cookies"
    },
    on: {
      "click": _vm.acceptCookies
    }
  }, [_vm._v("OK")])], 1)])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }